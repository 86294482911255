import { useState, useEffect } from 'react';

import useWindowDimensions from './useWindowDimensions';

export default function useMobile() {
	const { width: clientWidth } = useWindowDimensions();

	const [isMobile, setIsMobile] = useState(false);
	const [isPhone, setIsPhone] = useState(false);
	const [isTablet, setIsTablet] = useState(false);

	useEffect(() => {
		setIsMobile(clientWidth <= 991);
		setIsTablet(clientWidth <= 991);
		setIsPhone(clientWidth <= 767);
	}, [clientWidth]);

	return { isMobile, isPhone, isTablet };
}
