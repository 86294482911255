import { useState, useEffect } from 'react';

const getWindowDimensions = () => {
	if (typeof window !== 'undefined') {
		const doc = (document.compatMode === 'CSS1Compat') ? document.documentElement : document.body;
		const { clientWidth: width, clientHeight: height } = doc;

		return { width, height };
	}

	return { width: 0, height: 0 }; // SSR
};

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		const handleResize = () => {
			setWindowDimensions(getWindowDimensions());
		};

		if (typeof window !== 'undefined') {
			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}

		return false;
	}, []);

	return windowDimensions;
}
