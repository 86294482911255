import React, { useEffect } from 'react';
import Link from 'next/link';

import useLanguage from '../_hooks/useLanguage';
import useApp from '../_hooks/useApp';
import useAuth from '../_hooks/useAuth';
import useUser from '../_hooks/useUser';
import useConfig from '../_hooks/useConfig';

const HeaderNotification = () => {
	const { t } = useLanguage();
	const { appData: { headerNotitication }, appDispatch } = useApp();
	const { getHeaderNotification } = useUser();
	const { authenticated, userData } = useAuth(); // ,isProExistedAndExpired
	const { clientConfig } = useConfig();

	const updateNotification = () => {
		const n = getHeaderNotification();
		appDispatch({ type: 'SET_HEADER_NOTIFICATION', payload: n.type ? n : null });
	};

	let timer = null;

	useEffect(() => {
		if (clientConfig('PRO_LOCKED') && authenticated !== null) { // not checked user auth status yet ...
			if (authenticated === false) { // user is not logged in
				appDispatch({ type: 'SET_HEADER_NOTIFICATION', payload: { type: 'get-trial', color: 'graydark' } });
			}

			// user is logged in
			if (userData && Object.keys(userData).length > 0) {
				timer = setInterval(() => updateNotification(), 60000); // check every minute, if anything changed (ex. users trial expired, ...)
				updateNotification();
			}
		}

		return () => {
			if (typeof timer !== 'undefined') clearTimeout(timer);
		};
	}, [userData, authenticated]);

	if (headerNotitication === null) return null;

	switch (headerNotitication.type) {
	case 'get-trial':
		return <>{t('labelGetTrial')} <Link href="/register"><a>{t('btnRegister')}</a></Link></>;

	case 'trial-countdown':
		return <>{t('labelTrialAccountCountdown').replace('{{countdown}}', headerNotitication.countdown)} <Link href="/cenik"><a>{t('btnSelectProduct')}</a></Link></>;

	case 'trial-expired':
		return <>{t('labelTrialAccountExpired')} <Link href="/cenik"><a>{t('btnSelectProduct')}</a></Link></>;

	case 'pro-expired':
		return <>{t('labelProAccountExpired')} <Link href="/cenik"><a>{t('btnSelectProduct')}</a></Link></>;

	default:
		return null;
	}
};

export default HeaderNotification;
