import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import styles from './header.module.scss';
import useSiid from '../_hooks/useSiid';
import Navigation from '../navigation/navigation';
import HeaderNotification from './notification';
import useApp from '../_hooks/useApp';
import useAuth from '../_hooks/useAuth';

const Header = React.memo(({ variation }) => {
	const { appData: { headerNotitication, overlayPanel }, appDispatch } = useApp();
	const { authenticated } = useAuth();
	const { header, siidNm } = useSiid();
  
	const [navigation, setNavigation] = useState([]);
	useEffect(() => setNavigation(header.nav || []), [header.nav]);

	// Handle scroll
	const [scrolled, setScrolled] = useState(false);
	const [_scrollTop, _setScrollTop] = useState(0);
	const scrollTop = useRef(0);
	const [_scrollDir, _setScrollDir] = useState('down'); // down, up
	const scrollDir = useRef('down');

	const setScrollTop = (scrollY) => {
		_setScrollTop(scrollY);
		scrollTop.current = scrollY;
	};

	const setScrollDir = (d) => {
		_setScrollDir(d);
		scrollDir.current = d;
	};

	const handleScroll = () => {
		// Find out direction (threshold = 10px at least must be scrolled, to check): Math.abs(window.pageYOffset - scrollTop.current) >= 10 &&
		if (window.pageYOffset > 0) {
			const d = window.pageYOffset > scrollTop.current ? 'down' : 'up';
			if (d !== scrollDir.current) setScrollDir(d);
		}

		setScrollTop(window.pageYOffset);
		return true;
	};

	useEffect(() => {
		if (scrollTop.current > 50 && scrolled === false) {
			setScrolled(true);
		} else if (scrollTop.current < 50 && scrolled) {
			setScrolled(false);
		}
	}, [_scrollTop]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', () => handleScroll);
		};
	}, []);

	return (
		<header className={`${authenticated ? styles.authed : ''} ${styles.default} ${styles[variation] || ''} ${scrolled ? styles.scrolled : ''} ${styles[_scrollDir] || ''} ${headerNotitication ? styles.isHeaderNotification : ''} ${(overlayPanel && overlayPanel.name) ? `header-${overlayPanel.name}` : ''} ${styles[siidNm]}`}>
			<div className={`${styles.notification} ${styles[headerNotitication ? 'visible' : 'hidden']} ${headerNotitication ? styles[headerNotitication.color || 'red'] : ''}`}>
				<HeaderNotification />
				{headerNotitication && ['get-trial', 'pro-expired', 'trial-expired'].includes(headerNotitication.type) && <button type="button" onClick={() => appDispatch({ type: 'SET_HEADER_NOTIFICATION', payload: null })}><i className="icon times sm white" /></button>}
			</div>
			<div className="container">
				<div className="row">
					<div className={`col-12 ${styles.container}`}>
						<Link href="/"><a className={`${styles.logo} hideText`}>Mojedrazbe.com</a></Link>
						<div className={styles.pullRight}>
							<button type="button" className="d-flex d-lg-none toggler" onClick={() => appDispatch({ type: 'SET_OVERLAY_PANEL', payload: { name: 'mainNavigation' } })} aria-label="Mobile menu trigger">
								<span />
								<span />
								<span />
								<span />
							</button>
							<Navigation list={navigation} isNavActive={overlayPanel && overlayPanel.name === 'mainNavigation'} onClose={() => appDispatch({ type: 'SET_OVERLAY_PANEL', payload: null })} />
						</div>
					</div>
				</div>
			</div>
		</header>
	);
});

Header.defaultProps = {
	variation: 'home'
};

Header.propTypes = {
	variation: PropTypes.string
};

export default Header;
