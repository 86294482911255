import React from 'react';
import PropTypes from 'prop-types';

import styles from './tag.module.scss';

const Tag = ({ children, classes }) => {
	const c = [styles.default];
	if (classes) {
		classes.split(' ').map(cls => c.push(styles[cls]));
	}

	return <em className={c.join(' ')}>{children}</em>;
};

Tag.defaultProps = {
	classes: null
};

Tag.propTypes = {
	children: PropTypes.node.isRequired,
	classes: PropTypes.string
};

export default Tag;
